// Generated by Framer (6f17e57)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {ovKRHRgXs: {hover: true, pressed: true}};

const cycleOrder = ["ovKRHRgXs"];

const variantClassNames = {ovKRHRgXs: "framer-v-1pbxr4i"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translateY(-50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; tap?: any; link?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "ovKRHRgXs", title: HV0WyTncs = "Terms Of Use", tap: NOYKDGxyh, link: d1pHfNMPL, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ovKRHRgXs", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1py8vgx = activeVariantCallback(async (...args) => {
if (NOYKDGxyh) {
const res = await NOYKDGxyh(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-xPRYp", classNames)} style={{display: "contents"}}>
<Link href={d1pHfNMPL} openInNewTab><motion.a {...restProps} className={`${cx("framer-1pbxr4i", className)} framer-1fqhbic`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"ovKRHRgXs"} onTap={onTap1py8vgx} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"ovKRHRgXs-hover": {"data-framer-name": undefined}, "ovKRHRgXs-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy02MDA=", "--framer-font-family": "\"Poppins\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "600", "--framer-line-height": "24px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Terms Of Use</motion.p></React.Fragment>} className={"framer-i7ljsk"} data-framer-name={"Terms Of Use"} fonts={["GF;Poppins-600"]} layoutDependency={layoutDependency} layoutId={"Pc0qmGJjK"} style={{"--extracted-r6o4lv": "rgba(22, 22, 22, 0.5)", "--framer-paragraph-spacing": "0px"}} text={HV0WyTncs} transformTemplate={transformTemplate} transition={transition} variants={{"ovKRHRgXs-hover": {"--extracted-r6o4lv": "rgb(22, 22, 22)"}, "ovKRHRgXs-pressed": {"--extracted-r6o4lv": "rgb(22, 22, 22)"}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-xPRYp [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xPRYp .framer-1fqhbic { display: block; }", ".framer-xPRYp .framer-1pbxr4i { cursor: pointer; height: 24px; overflow: hidden; position: relative; text-decoration: none; width: 100px; }", ".framer-xPRYp .framer-i7ljsk { flex: none; height: auto; left: 0px; position: absolute; right: 0px; top: 50%; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }", ".framer-xPRYp .framer-v-1pbxr4i .framer-1pbxr4i { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"deMSQhAVW":{"layout":["fixed","fixed"]},"PDiOwsJWP":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"HV0WyTncs":"title","NOYKDGxyh":"tap","d1pHfNMPL":"link"}
 */
const FramerXuvTSbpS6: React.ComponentType<Props> = withCSS(Component, css, "framer-xPRYp") as typeof Component;
export default FramerXuvTSbpS6;

FramerXuvTSbpS6.displayName = "Link";

FramerXuvTSbpS6.defaultProps = {height: 24, width: 100};

addPropertyControls(FramerXuvTSbpS6, {HV0WyTncs: {defaultValue: "Terms Of Use", displayTextArea: false, title: "Title", type: ControlType.String}, NOYKDGxyh: {title: "Tap", type: ControlType.EventHandler}, d1pHfNMPL: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerXuvTSbpS6, [{family: "Poppins", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/XuvTSbpS6:default", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf", weight: "600"}])